import { withRouter } from 'react-router-dom';
import { projectData } from '../data/Data';
import styled from 'styled-components';
import ProjectCard from '../Components/ProjectCard';

function NewProject(props) {
  // console.warn(props);

  const projectUrl = props.match.params.url;
  const project = projectData.find((project) => project.url === projectUrl);

  return (
    <StyledSection>
      <Wrapper>
        <SectionTitle>{project.name}</SectionTitle>
        <ProjectCard
          technologies={project.technologies}
          pictures={project.pictures}
          description={project.description}
          deploymentLink={project.deploymentLink}
          gitLink={project.gitLink}
          key={project.name}
          name={project.name}
        />
      </Wrapper>
    </StyledSection>
  );
}

export default withRouter(NewProject);

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledSection = styled.section`
  font-size: ${({ isMobile }) => (isMobile ? '1em' : '1.1em')};
  color: white;
  /* margin-- */
`;
const SectionTitle = styled.h2`
  margin-bottom: 0.25em;
  color: white;
`;
