import ReactDom from 'react-dom';
import { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Document, Page, pdfjs } from 'react-pdf';
import LoadingSpinner from '../assets/LoadingSpinner.webp';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import useScreenLock from '../hooks/useScreenLock';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PdfViewer({ file, title, isOpen, setIsOpen }) {
  // eslint-disable-next-line
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useScreenLock(isOpen);

  function prevPage() {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
      setIsLoading(true);
      document.getElementById('myDocument').scrollIntoView();
    }
  }

  function nextPage() {
    if (pageNumber < numPages) {
      setPageNumber((prevPage) => prevPage + 1);
      document.getElementById('myDocument').scrollIntoView();
      setIsLoading(true);
    }
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function closeHandler() {
    setIsOpen(false);
    setPageNumber(1);
    setIsLoading(true);
  }

  if (!isOpen) return null;
  return ReactDom.createPortal(
    <>
      <BackgroundStyler />
      <ModalStyler>
        <Container id="myDocument">
          <PDFContainer>
            <DocumentContainer>
              <StyledDocument
                loading={''}
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <StyledPage
                  pageNumber={pageNumber}
                  scale={isMobile ? 1 : 1.55}
                  onRenderSuccess={() => setIsLoading(false)}
                />
                <SpinnerContainer isLoading={isLoading}>
                  <SpinnerStyler
                    isMobile={isMobile}
                    src={LoadingSpinner}
                    style={{ margin: 'auto', display: 'block' }}
                    alt="Loading"
                  />
                </SpinnerContainer>
                <CloseButton
                  onClick={closeHandler}
                  isLoading={isLoading}
                  isMobile={isMobile}
                >
                  &times;
                </CloseButton>
                <ButtonContainer isMobile={isMobile}>
                  <button onClick={prevPage}>‹</button>
                  <span>
                    {pageNumber} of {numPages}
                  </span>
                  <button onClick={nextPage}>›</button>
                </ButtonContainer>
              </StyledDocument>
            </DocumentContainer>
          </PDFContainer>
        </Container>
      </ModalStyler>
    </>,
    document.getElementById('portal')
  );
}

const CloseButton = styled.button`
  position: absolute;
  top: 1%;
  margin: 0 auto;
  transform: translateY(-100%);
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: ${({ isMobile }) => (isMobile ? '1.5rem' : '2rem')};
  padding: 0;
  outline: none;
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
`;

const SpinnerStyler = styled.img`
  display: 'block';
  width: ${({ isMobile }) => (isMobile ? '100px' : '200px')};
`;
const BackgroundStyler = styled.div`
  background-color: var(--clr-bg-modal);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const ModalStyler = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'none' : '')};
  background: none;
  border-radius: 10px;
  left: 50%;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: ${({ isMobile }) => (isMobile ? '45%' : '50%')};
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-height: 100vh;
  overflow-y: auto;
  max-height: ${({ isMobile }) => (isMobile ? '100vh' : 'calc(100vh - 210px)')};
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SpinnerContainer = styled.div`
  display: ${({ isLoading }) => (isLoading ? '' : 'none')};
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
  span {
    font: inherit;
    font-size: ${({ isMobile }) => (isMobile ? '0.9em' : '1em')};
    padding: 0 0.5em;
  }
  button {
    width: ${({ isMobile }) => (isMobile ? '' : '44px')};
    height: ${({ isMobile }) => (isMobile ? '' : '44px')};
    background: white;
    border: 0;
    font: inherit;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
  }
  button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const DocumentContainer = styled.div`
  margin-bottom: 1em;
`;

const StyledPage = styled(Page)`
  /* max-width: calc('100% - 10%'); */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
  position: relative;
  canvas {
    max-width: 100%;
    height: auto !important;
  }
`;

const StyledDocument = styled(Document)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    div {
      opacity: 1;
    }
  }
`;

const PDFContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin: 2rem 0; */
  padding: 15px;
`;

const Container = styled.div`
  overflow: hidden;
`;
