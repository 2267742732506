import flixbud1 from '../assets/projects/flixbuddies/flixbud1.webp';
import flixbud2 from '../assets/projects/flixbuddies/flixbud2.webp';
import flixbud3 from '../assets/projects/flixbuddies/flixbud3.webp';
import flixbud4 from '../assets/projects/flixbuddies/flixbud4.webp';
import flixbud5 from '../assets/projects/flixbuddies/flixbud5.webp';
import flixbud6 from '../assets/projects/flixbuddies/flixbud6.webp';
import flixbud7 from '../assets/projects/flixbuddies/flixbud7.webp';

import rickmorty1 from '../assets/projects/rickandmorty/rickmorty1.webp';
import rickmorty2 from '../assets/projects/rickandmorty/rickmorty2.webp';
import rickmorty3 from '../assets/projects/rickandmorty/rickmorty3.webp';
import rickmorty4 from '../assets/projects/rickandmorty/rickmorty4.webp';
import rickmorty5 from '../assets/projects/rickandmorty/rickmorty5.webp';
import rickmorty6 from '../assets/projects/rickandmorty/rickmorty6.webp';
import rickmorty7 from '../assets/projects/rickandmorty/rickmorty7.webp';

import form1 from '../assets/projects/formApp/form1.webp';
import form2 from '../assets/projects/formApp/form2.webp';
import form3 from '../assets/projects/formApp/form3.webp';
import form4 from '../assets/projects/formApp/form4.webp';
import form5 from '../assets/projects/formApp/form5.webp';
import form6 from '../assets/projects/formApp/form6.webp';

import todo1 from '../assets/projects/todoApp/todo1.webp';
import todo2 from '../assets/projects/todoApp/todo2.webp';
import todo3 from '../assets/projects/todoApp/todo3.webp';
import todo4 from '../assets/projects/todoApp/todo4.webp';
import todo5 from '../assets/projects/todoApp/todo5.webp';
import todo6 from '../assets/projects/todoApp/todo6.webp';

import trivia1 from '../assets/projects/gamingTrivia/trivia1.webp';
import trivia2 from '../assets/projects/gamingTrivia/trivia2.webp';
import trivia3 from '../assets/projects/gamingTrivia/trivia3.webp';
import trivia4 from '../assets/projects/gamingTrivia/trivia4.webp';
import trivia5 from '../assets/projects/gamingTrivia/trivia5.webp';
import trivia6 from '../assets/projects/gamingTrivia/trivia6.webp';

export const documents = [
  {
    file: '/cvGer.pdf',
    title: 'Curriculum Vitae',
    subtitle: 'German',
    id: '1',
  },
  {
    file: '/cvEng.pdf',
    title: 'Curriculum Vitae',
    subtitle: 'English',
    id: '2',
  },
  { file: '/certificate.pdf', title: 'Certificate', subtitle: '', id: '3' },
];

export const projectData = [
  {
    id: '1',
    name: 'Flixbuddies',
    url: 'flixbuddies',
    technologies: [
      'React',
      'React-Router',
      'Styled-Components',
      'PropTypes',
      'Styleguidist',
      'axios',
      'Jest',
      'Cypress',
      'LocalStorage',
      'Node.js',
      'MongoDB',
      'Express',
      'Mongoose',
      'AdobeXD',
    ],
    description:
      "Users of FLIXBUDDIES cannot only learn which movies and shows are currently trending and create a watchlist and a list of favorites, but also share them with friends. For this, movie data is fetched from an external API. The Watchlist, the Favorites and user data is stored and fetched (to render friends) using an own backend. In order to hide the API key – issued by the movie database – in network traffic, the backend also serves as a middleware to handle the get requests. The app is a digital journeyman's piece and marks the successful completion of my 3 month coding bootcamp at neue fische, upon which I became a certified Junior Web Developer. The functionalities used in this app represent my coding skills of April 2021.",
    pictures: [
      flixbud1,
      flixbud2,
      flixbud3,
      flixbud4,
      flixbud5,
      flixbud6,
      flixbud7,
    ],
    deploymentLink: '',
    gitLink: 'https://github.com/danielmolnar/capstone-project',
  },
  {
    id: '2',
    name: 'Rick & Morty',
    url: 'rick-and-morty',
    technologies: [
      'React',
      'React-Router',
      'Styled-Components',
      'PropTypes',
      'Styleguidist',
      'axios',
      'LocalStorage',
      'Node.js',
    ],
    description:
      'This app was part of the teaching program at the coding bootcamp I visited. It was conducted as a team project with each team consisting of 3-4 students. The main requirement was to fetch data from an external api in order to render cards for each character. Furthermore, we implemented features we just recently learned including React Router, LocalStorage, propTypes and StyleGuidist. The contributors to this app are listed in the according Github repository, which you can find by visiting the link down below.',
    pictures: [
      rickmorty1,
      rickmorty2,
      rickmorty3,
      rickmorty4,
      rickmorty5,
      rickmorty6,
      rickmorty7,
    ],
    deploymentLink: 'https://rick-and-morty-app-iota.vercel.app/',
    gitLink: 'https://github.com/danielmolnar/rick-and-morty-app',
  },
  {
    id: '3',
    name: 'Gaming Trivia',
    url: 'gaming-trivia',
    technologies: [
      'React',
      'axios',
      'Node.js',
      'PropTypes',
      'Styled-Components',
    ],
    description:
      'This app is a gaming trivia, which fetches questions from an external API. The challenging part was to transform undesired special characters provided by the API and how to display questions, which were already answered by the user.',
    pictures: [trivia1, trivia2, trivia3, trivia4, trivia5, trivia6],
    deploymentLink: 'https://quizapp-chi.vercel.app/',
    gitLink: 'https://github.com/danielmolnar/quiz',
  },
  {
    id: '4',
    name: 'PC Shop Form',
    url: 'pc-shop-form',
    technologies: [
      'React',
      'Styled-Components',
      'PropTypes',
      'Styleguidist',
      'Jest Testing',
      'Node.js',
    ],
    description:
      'The main feature of this app is to validate the data that is populated in the form (e.g. valid email address), before it gets submitted. For this, validator functions were coded, implemented and tested with JEST Testing. Submitting a faulty form results in an error prompt.',
    pictures: [form1, form2, form3, form4, form5, form6],
    deploymentLink: 'https://form-app-silk.vercel.app/',
    gitLink: 'https://github.com/danielmolnar/form-app',
  },
  {
    id: '5',
    name: 'To-Do App',
    url: 'to-do-app',
    technologies: [
      'JavaScript Vanilla',
      'CSS',
      'HTML',
      'Node.js',
      'LocalStorage',
    ],
    description:
      'This app allows you to add and remove To-Do-Items and display them depending on their current state (all, completed, uncompleted). The List is stored in LocalStorage. ',
    pictures: [todo1, todo2, todo3, todo4, todo5, todo6],
    deploymentLink: 'https://to-do-list-black-seven.vercel.app/',
    gitLink: 'https://github.com/danielmolnar/to-do-list',
  },
];
