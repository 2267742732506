import { projectData } from '../data/Data';
import styled from 'styled-components';
import { InformationCircle } from '@styled-icons/ionicons-sharp/InformationCircle';
import { InformationCircle as ScrollInfo } from '@styled-icons/ionicons-sharp/InformationCircle';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { MoveHorizontal } from '@styled-icons/boxicons-regular/MoveHorizontal';

// <Link to={'/project/' + project.id}>
// <StyledImage src={project.pictures[0]} alt="" key={index} />
// </Link>

export default function NewProjects() {
  const [desktopInfo, setDesktopInfo] = useState(false);
  let history = useHistory();

  const noOverflow = projectData.length < 5 && !isMobile;
  const noInfoIcon = projectData.length < 5 || isMobile;
  const needsPadding = projectData.length >= 5 || isMobile;

  return (
    <StyledSection>
      <Wrapper>
        <SectionTitle>Projects</SectionTitle>
        <CardWrapper>
          <Card isMobile={isMobile}>
            <InfoWrapper>
              <InfoContainer
                desktopInfo={desktopInfo}
                isMobile={isMobile}
                noInfoIcon={noInfoIcon}
                noOverflow={noOverflow}
              >
                <p>
                  Use "shift" + "mousewheel" in order to scroll through the
                  thumbnails horizontally.
                </p>
                <span onClick={() => setDesktopInfo(!desktopInfo)}>
                  <ScrollIcon />
                  <ScrollInfoIcon />
                </span>
              </InfoContainer>
            </InfoWrapper>
            <Cards noOverflow={noOverflow}>
              {projectData.map((project, index) => (
                <ImageWrapper
                  key={index}
                  isMobile={isMobile}
                  noOverflow={noOverflow}
                  needsPadding={needsPadding}
                >
                  <StyledImage src={project.pictures[0]} alt="" key={index} />
                  <InfoIcon
                    onClick={() => history.push('/project/' + project.url)}
                  />
                </ImageWrapper>
              ))}
            </Cards>
          </Card>
        </CardWrapper>
      </Wrapper>
    </StyledSection>
  );
}
const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ScrollIcon = styled(MoveHorizontal)`
  color: var(--secondary-100);
  width: 40px;
`;

const ScrollInfoIcon = styled(ScrollInfo)`
  color: var(--secondary-100);
  width: 40px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: solid white 1px; */
  transition: transform 550ms;
  margin-right: 35px;
  padding: 1rem 0;
  /* position: relative; */
  flex-shrink: 0;
  :last-child {
    margin-right: ${({ noOverflow }) => (noOverflow ? '0px' : '35px')};
    padding-right: ${({ needsPadding }) => (needsPadding ? '35px' : '0')};
  }

  &:hover {
    transform: ${({ isMobile }) => (isMobile ? 'scale(1.06)' : 'scale(1.09)')};
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledSection = styled.section`
  font-size: ${({ isMobile }) => (isMobile ? '1em' : '1.1em')};
  color: white;
  /* margin-- */
`;
const SectionTitle = styled.h2`
  margin-bottom: 0.25em;
  color: white;
`;

const InfoContainer = styled.div`
  display: ${({ noInfoIcon }) => (noInfoIcon ? 'none' : 'flex')};
  align-items: center;
  justify-content: flex-end;
  max-width: 1020px;
  width: 100%;
  margin-right: 2rem;
  span {
    cursor: pointer;
    padding: 0.25em;
    transition: transform 550ms;
    &:hover {
      transform: scale(1.15);
    }
  }
  p {
    visibility: ${({ desktopInfo }) => (desktopInfo ? '' : 'hidden')};
    margin-right: 1rem;
  }
`;

// const InfoContainer = styled.div`
//   display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
//   align-items: flex-end;
//   flex-direction: column;
//   justify-content: center;
//   div {
//     cursor: pointer;
//     display: flex;
//     padding: 5px;
//     transition: transform 450ms;
//     &:hover {
//       transform: scale(1.1);
//     }
//   }
//   p {
//     display: ${({ desktopInfo }) => (desktopInfo ? '' : 'none')};
//     font-size: 1.1rem;
//     padding: 0rem 1rem;
//     text-align: right;
//   }
// `;

const InfoIcon = styled(InformationCircle)`
  color: white;
  width: 35px;
  height: 35px;
  cursor: pointer;
  /* transition: transform 550ms; */
  /* position: absolute; */
  transform: translateY(-62px);
  margin: 0;
  /* &:hover {
    transform: scale(1.2);
  } */
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Card = styled.div`
  background: var(--clr-bg-opac);
  border-radius: 5px;
  padding: 25px 0;
  margin: 50px 0px;
  width: 100%;
`;

const Cards = styled.div`
  display: flex;
  justify-content: ${({ noOverflow }) => (noOverflow ? 'center' : '')};
  /* border: solid white 1px; */
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 1rem 2rem;
  /* padding: 50px 40px; */
  /* margin-bottom: 1rem; */
  /* margin-left: 4rem; */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledImage = styled.img`
  height: 500px;
  transition: transform 550ms;
  margin-bottom: 1rem;
  /* &:hover {
    transform: scale(1.15);
  } */
`;
