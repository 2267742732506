import styled from 'styled-components';
import LogoStyle from '../assets/Logo.svg';
import { keyframes } from 'styled-components';

export default function Logo() {
  return (
    <Coin>
      <Front></Front>
    </Coin>
  );
}

const diameter = '150px';
const turntime = '8s';
const rotate3d = keyframes`
 0% {
      transform: perspective(1000px) rotateY(0deg);
    }

    100% {
      transform: perspective(1000px) rotateY(360deg);
    }
`;

const Coin = styled.div`
  width: ${diameter};
  height: ${diameter};
  transform-style: preserve-3d;
  animation: ${rotate3d} ${turntime} linear infinite;
  transition: all 0.3s;
`;

const Front = styled.div`
  width: ${diameter};
  height: ${diameter};
  border-radius: 50%;
  overflow: hidden;
  background-image: url(${LogoStyle});
  background-size: cover;
  transform: translateZ(20px/2);
`;
