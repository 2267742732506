import styled from 'styled-components';
import ProfilePic from '../assets/ProfilePic.webp';

export default function Home() {
  return (
    <Wrapper>
      <StyledSection>
        <SectionTitle>
          Hi, I am <strong>Daniel Molnar</strong>
        </SectionTitle>
        <SectionSubtitle>Software Developer</SectionSubtitle>
        <img src={ProfilePic} alt="a portrait of Daniel Molnar in sepia" />
      </StyledSection>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const StyledSection = styled.section`
  @media (min-width: 600px) {
    display: grid;
    width: min-content;
    grid-column-gap: 1em;
    grid-template-areas:
      'img title'
      'img subtitle';
    grid-template-columns: min-content max-content;
    p {
      align-self: start;
      grid-column: -1/1;
      grid-row: 2;
      text-align: right;
      position: relative;
      left: -1.5em;
      width: calc(100% + 1.5em);
    }
  }

  img {
    grid-area: img;
    min-width: 250px;
    position: relative;
    z-index: 2;
    box-shadow: var(--bs);
  }
`;

const SectionTitle = styled.h1`
  margin-bottom: 0.25em;
  font-weight: var(--fw-reg);
  color: white;
  strong {
    display: block;
  }
`;
const SectionSubtitle = styled.p`
  margin: 0;
  padding: 0.25em 1em;
  font-size: var(--fs-h3);
  background-color: white;
  margin-bottom: 1em;
  display: inline-block;
`;
