import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { GameController } from '@styled-icons/ionicons-solid/GameController';
import { Books } from '@styled-icons/icomoon/Books';
import { Computer } from '@styled-icons/remix-line/Computer';
import { CodeBlock } from '@styled-icons/boxicons-regular/CodeBlock';
import { Tree } from '@styled-icons/bootstrap/Tree';
import { isIOS } from 'react-device-detect';
import LogoSVG from '../assets/Logo.svg';
import Logo from '../Components/Logo';

export default function About() {
  return (
    <StyledSection>
      <Wrapper>
        <SectionTitle>About me</SectionTitle>
        <AboutMe>
          <BackdropLogo src={LogoSVG} alt="" isIOS={isIOS} />
          <LogoWrapper isIOS={isIOS}>
            <Logo />
          </LogoWrapper>
          <SectionSubtitle>Motivation</SectionSubtitle>

          <Motivation isMobile={isMobile}>
          After several years in sales and strategic partnership roles within software organizations, I have successfully transitioned my career to 
          software development, channeling my passion for coding into a professional vocation. My journey began with an intensive coding boot camp at 
          NEUE FISCHE GmbH, where I honed my skills in web development using technologies like JavaScript, React, HTML5, CSS3, and Node.js. 
          This experience culminated in the creation of a journeyman’s piece, FLIXBUDDIES, showcasing my ability to develop responsive web applications.
          Since then, I have been working as a Software Developer at PHNTM GmbH, contributing to the development of enterprise solutions for web, iOS, and 
          Android platforms. My role involves utilizing Flutter and Dart in projects for renowned, multinational companies, ensuring high-quality deliverables 
          through regular code reviews, extensive testing, and adherence to best-practice methods. My experience in pair programming and active participation in 
          agile development processes, including sprints and daily stand-ups, has significantly enhanced my collaborative and technical skills.
          The blend of my background in sales and partnership management with my current software development expertise makes me uniquely positioned to 
          understand and fulfill user needs, both from a technical-functional and user-experience perspective. 
          I remain enthusiastic about the creative and inventive aspects of coding and am motivated by the challenges of developing seamless, 
          user-centric applications.
          </Motivation>
          <SectionSubtitle>Hobbies</SectionSubtitle>
          <HobbyWrapper>
            <Hobbies>
              <Gaming />
              <p>Gaming</p>
            </Hobbies>
            <Hobbies>
              <Coding />
              <p>Coding</p>
            </Hobbies>
            <Hobbies>
              <Hardware />
              <p>Hardware</p>
            </Hobbies>
            <Hobbies>
              <Literature />
              <p>Literature</p>
            </Hobbies>
            <Hobbies>
              <Nature />
              <p>Nature</p>
            </Hobbies>
          </HobbyWrapper>
        </AboutMe>
      </Wrapper>
    </StyledSection>
  );
}

const StyledSection = styled.section``;

const BackdropLogo = styled.img`
  display: ${({ isIOS }) => (isIOS ? '' : 'none')};
`;

const LogoWrapper = styled.div`
  margin-top: 0.3rem;
  display: ${({ isIOS }) => (isIOS ? 'none' : 'flex')};
  justify-content: center;
  width: 100%;
`;

const Gaming = styled(GameController)`
  color: white;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const Nature = styled(Tree)`
  color: white;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const Coding = styled(CodeBlock)`
  color: white;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const Hardware = styled(Computer)`
  color: white;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const Literature = styled(Books)`
  color: white;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const HobbyWrapper = styled.div`
  display: grid;
  align-self: center;
  gap: 5rem;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;

const Hobbies = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  margin-right: 1rem;
  color: white;
  p {
    margin-top: 0.3rem;
    text-align: center;
    font-size: 1.1em;
  }
`;

const SectionSubtitle = styled.h3`
  margin: 2em 0.5em;
  color: white;
  display: inline-block;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  margin-bottom: 0.25em;
  color: white;
`;

const AboutMe = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: var(--clr-bg-opac);
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 60px;

  img {
    margin: 0 auto;
    margin-top: 20px;
    width: 120px;
  }
`;

const Motivation = styled.p`
  color: white;
  font-size: ${({ isMobile }) => (isMobile ? '1em' : '1.25em')};
  line-height: 1.75;
  padding: 0;
  margin: 0 0.5em;
`;
