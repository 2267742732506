import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Home } from '@styled-icons/heroicons-outline/Home';
import { Profile } from '@styled-icons/icomoon/Profile';
import { NetworkCheck } from '@styled-icons/material-twotone/NetworkCheck';
import { CodeBlock } from '@styled-icons/boxicons-regular/CodeBlock';
import { PersonCircle } from '@styled-icons/bootstrap/PersonCircle';

export default function Menu({ open, setOpen }) {
  const location = useLocation();
  const isHome = location.pathname === '/';
  const isAbout = location.pathname === '/about';
  const isSkills = location.pathname === '/skills';
  const isProjects = location.pathname === '/projects';
  const isVita = location.pathname === '/vita';

  return !isMobile ? (
    <StyledMenu open={open}>
      <MenuWrapper>
        <BurgerLink to="/">
          <HomeWrap isHome={isHome}>
            <CloseWrapper>
              <p>Home</p>
            </CloseWrapper>
          </HomeWrap>
        </BurgerLink>
        <BurgerLink to="/about">
          <AboutWrap isAbout={isAbout}>
            <CloseWrapper>
              <p>About me</p>
            </CloseWrapper>
          </AboutWrap>
        </BurgerLink>
        <BurgerLink to="/skills">
          <SkillsWrap isSkills={isSkills}>
            <CloseWrapper>
              <p>Skills</p>
            </CloseWrapper>
          </SkillsWrap>
        </BurgerLink>
        <BurgerLink to="/projects">
          <ProjectsWrap isProjects={isProjects}>
            <CloseWrapper>
              <p>Projects</p>
            </CloseWrapper>
          </ProjectsWrap>
        </BurgerLink>
        <BurgerLink to="/vita">
          <VitaWrap isVita={isVita}>
            <CloseWrapper>
              <p>Vita</p>
            </CloseWrapper>
          </VitaWrap>
        </BurgerLink>
      </MenuWrapper>
    </StyledMenu>
  ) : (
    <StyledMobileMenu open={open}>
      <MobileMenuWrapper>
        <BurgerLink to="/">
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <HomeIcon />
            <p>Home</p>
          </CloseMobileWrapper>
        </BurgerLink>
        <BurgerLink to="/about">
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <AboutIcon />
            <p>About me</p>
          </CloseMobileWrapper>
        </BurgerLink>
        <BurgerLink to="/skills">
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <SkillsIcon />
            <p>Skills</p>
          </CloseMobileWrapper>
        </BurgerLink>
        <BurgerLink to="/projects">
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <ProjectsIcon />
            <p>Projects</p>
          </CloseMobileWrapper>
        </BurgerLink>
        <BurgerLink to="/vita">
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <VitaIcon />
            <p>Vita</p>
          </CloseMobileWrapper>
        </BurgerLink>
      </MobileMenuWrapper>
    </StyledMobileMenu>
  );
}

const HomeWrap = styled.div`
  background: ${({ isHome }) => (isHome ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const AboutWrap = styled.div`
  background: ${({ isAbout }) => (isAbout ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const VitaWrap = styled.div`
  background: ${({ isVita }) => (isVita ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const SkillsWrap = styled.div`
  background: ${({ isSkills }) => (isSkills ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const ProjectsWrap = styled.div`
  background: ${({ isProjects }) => (isProjects ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const StyledMenu = styled.nav`
  background: var(--clr-bg);
  width: 100%;
  box-shadow: var(--bs2);
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  flex-wrap: wrap;
`;

const StyledMobileMenu = styled.nav`
  padding: 0.25rem 0;
  background: var(--clr-bg);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  will-change: transform;
  text-align: left;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  width: 40vh;
  z-index: 3;
  @media (max-width: 800px) {
    width: 35vh;
  }
  @media (max-width: 500px) {
    width: 30vh;
  }
`;

const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* margin-top: 0rem; */
  height: 80%;
  width: 100%;
`;

const BurgerLink = styled(NavLink)`
  text-decoration: none;
`;

const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 8rem;
  color: white;
  p {
    font-size: 1.2rem;
  }
`;

const CloseMobileWrapper = styled.div`
  display: flex;
  align-items: center;
  /* height: 3rem; */
  color: white;
  &:active,
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const HomeIcon = styled(Home)`
  color: white;
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;

const VitaIcon = styled(Profile)`
  color: white;
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;

const AboutIcon = styled(PersonCircle)`
  color: white;
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;

const SkillsIcon = styled(NetworkCheck)`
  color: white;
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;

const ProjectsIcon = styled(CodeBlock)`
  color: white;
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;
