import styled from 'styled-components';
import { useState } from 'react';
import PdfViewer from '../Components/PdfViewer';
import { Preview } from '@styled-icons/material/Preview';

export default function DocumentCard({ file, title, subtitle }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CardWrapper isOpen={isOpen}>
      <DocumentWrapper>
        <TitleWrapper>
          <SectionSubtitle>
            {title}
            <br />
            {subtitle}
          </SectionSubtitle>
        </TitleWrapper>
        <ShowIcon onClick={() => setIsOpen(true)} />
      </DocumentWrapper>
      <PdfViewer
        isOpen={isOpen}
        file={file}
        title={title}
        setIsOpen={setIsOpen}
      />
    </CardWrapper>
  );
}

const TitleWrapper = styled.div`
  min-width: 15rem;
`;

const ShowIcon = styled(Preview)`
  color: white;
  width: 40px;
  height: 40px;
  margin: 0.25em 1em;
  transition: transform 550ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.15);
  }
`;

const DocumentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

const SectionSubtitle = styled.h3`
  margin: 0;
  padding: 0.25em 1em;
  color: white;
  display: inline-block;
  line-height: 1.3;
`;
