import { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import Sidebar from './Components/Ui/Sidebar';
import Home from './Pages/Home';
import Skills from './Pages/Skills';
import About from './Pages/About';
import NewProjects from './Pages/NewProjects';
import Footer from './Components/Footer';
import NewProject from './Pages/NewProject';
import ScrollToTop from './hooks/useScrollToTop';
import { isIOS } from 'react-device-detect';
import { isMobile } from 'react-device-detect';
import Background from '../src/assets/Background.webp';
import BackgroundMobile from '../src/assets/BackgroundMobile.webp';
import Vita from './Pages/Vita';

import styled from 'styled-components';

function App() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ScrollToTop>
        <Sidebar setOpen={setOpen} open={open} />
        <BackGround isIOS={isIOS} isMobile={isMobile}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route path="/skills">
              <Skills />
            </Route>
            <Route path="/projects">
              <NewProjects />
            </Route>
            <Route exact path="/project/:url">
              <NewProject />
            </Route>
            <Route path="/vita">
              <Vita />
            </Route>
          </Switch>
        </BackGround>
        <Footer open={open} />
      </ScrollToTop>
    </>
  );
}

export default App;

const BackGround = styled.div`
  background: url(${isMobile ? BackgroundMobile : Background});
  background-size: ${({ isMobile }) =>
    isMobile ? '1080px  1920px' : '3840px  1794px'};
  background-position: auto;
  background-attachment: ${({ isIOS }) => (isIOS ? '' : 'fixed')};
  min-height: 100vh;
  margin-bottom: 25px;
`;
