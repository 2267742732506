import styled from 'styled-components';
import { Github } from '@styled-icons/fa-brands/Github';
import { LinkedinWithCircle } from '@styled-icons/entypo-social/LinkedinWithCircle';
import { XingWithCircle } from '@styled-icons/entypo-social/XingWithCircle';
import { MailWithCircle } from '@styled-icons/entypo-social/MailWithCircle';
import { isMobile } from 'react-device-detect';

export default function Footer() {
  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';

  return (
    <StyledFooter>
      <Wrapper>
        <a href="https://github.com/danielmolnar" target={target} rel={rel}>
          <GitLogo />
        </a>
        <a
          href="https://www.linkedin.com/in/danielmolnarprofile/"
          target={target}
          rel={rel}
        >
          <LinkedIn />
        </a>
        <a
          href="https://www.xing.com/profile/Daniel_Molnar23/cv"
          target={target}
          rel={rel}
        >
          <Xing />
        </a>
        <a href="mailto:daniel-molnar@web.de" target={target} rel={rel}>
          <Mail />
        </a>
      </Wrapper>
    </StyledFooter>
  );
}
const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
  margin: 0 auto;

  max-width: 900px;

  a {
    color: white;
    text-decoration: none;
  }

  img {
    max-width: 50px;
    color: white;
  }
`;

const StyledFooter = styled.footer`
  color: white;
  width: 100%;
  background-color: var(--clr-bg);
  bottom: 0;
  position: fixed;
  z-index: 5;
  box-shadow: var(--bs2);
`;

const GitLogo = styled(Github)`
  color: white;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const LinkedIn = styled(LinkedinWithCircle)`
  color: white;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const Xing = styled(XingWithCircle)`
  color: white;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const Mail = styled(MailWithCircle)`
  color: white;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;
