import styled from 'styled-components';
import JavaScriptLogo from '../assets/JavaScriptLogo.svg';
import ReactLogo from '../assets/ReactLogo.svg';
import CssLogo from '../assets/CssLogo.svg';
import HtmlLogo from '../assets/HtmlLogo.svg';
import NodeLogo from '../assets/NodeLogo.svg';
import NpmLogo from '../assets/NpmLogo.svg';
import GithubLogo from '../assets/GithubLogo.svg';
import CypressLogo from '../assets/CypressLogo.svg';
import MongoLogo from '../assets/MongoLogo.svg';
import StyleGuidistLogo from '../assets/StyleGuidistLogo.svg';
import StyledComponentsLogo from '../assets/StyledComponentsLogo.svg';
import MongooseLogo from '../assets/MongooseLogo.svg';
import GitLogo from '../assets/GitLogo.svg';
import ExpressLogo from '../assets/ExpressLogo.svg';
import FlutterLogo from '../assets/FlutterLogo.png';
import DartLogo from '../assets/DartLogo.png';
import FireBaseLogo from '../assets/FireBaseLogo.png';
import { isMobile } from 'react-device-detect';

export default function Skills() {
  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';

  return (
    <StyledServices>
      <Wrapper>
        <SectionTitle>My Skills</SectionTitle>
        <Card>
          <GridWrapper>
            <ServiceContainer>
            <Service>
                <a
                  href="https://flutter.dev"
                  target={target}
                  rel={rel}
                >
                  <FlutterImg src={FlutterLogo} alt="Flutter Logo" />
                </a>
                <p>Flutter</p>
              </Service>
              <Service>
                <a
                  href="https://dart.dev/"
                  target={target}
                  rel={rel}
                >
                  <DartImg src={DartLogo} alt="Dart Logo" />
                </a>
                <p>Dart</p>
              </Service>
              <Service>
                <a href="https://reactjs.org/" target={target} rel={rel}>
                  <ReactImg src={ReactLogo} alt="React Logo" />
                </a>
                <p>React</p>
              </Service>
              <Service>
                <a
                  href="https://firebase.google.com/"
                  target={target}
                  rel={rel}
                >
                  <FlutterImg src={FireBaseLogo} alt="Firebase Logo" />
                </a>
                <p>Firebase</p>
              </Service>
              <Service>
                <a
                  href="https://developer.mozilla.org/en-US/docs/Learn/JavaScript/First_steps/What_is_JavaScript"
                  target={target}
                  rel={rel}
                >
                  <JsImg src={JavaScriptLogo} alt="JavaScript Logo" />
                </a>
                <p>JavaScript</p>
              </Service>
              <Service>
                <a
                  href="https://www.w3schools.com/css/css_intro.asp"
                  target={target}
                  rel={rel}
                >
                  <CssImg src={CssLogo} alt="CSS Logo" />
                </a>
                <p>CSS</p>
              </Service>
              <Service>
                <a
                  href="https://www.w3schools.com/html/html_intro.asp"
                  target={target}
                  rel={rel}
                >
                  <HtmlImg src={HtmlLogo} alt="HTML Logo" />
                </a>
                <p>HTML</p>
              </Service>
              <Service>
                <a
                  href="https://www.w3schools.com/nodejs/nodejs_intro.asp"
                  target={target}
                  rel={rel}
                >
                  <NodeImg src={NodeLogo} alt="Node.js Logo" />
                </a>
                <p>Node.js</p>
              </Service>
              <Service>
                <a
                  href="https://www.w3schools.com/whatis/whatis_npm.asp"
                  target={target}
                  rel={rel}
                >
                  <NpmImg src={NpmLogo} alt="npm Logo" />
                </a>
                <p>npm</p>
              </Service>
              <Service>
                <a
                  href="https://en.wikipedia.org/wiki/GitHub"
                  target={target}
                  rel={rel}
                >
                  <GithubImg src={GithubLogo} alt="Github Logo" />
                </a>
                <p>Github</p>
              </Service>
              <Service>
                <a
                  href="https://en.wikipedia.org/wiki/Git"
                  target={target}
                  rel={rel}
                >
                  <GitImg src={GitLogo} alt="Git Logo" />
                </a>
                <p>Git</p>
              </Service>
              <a
                href="https://docs.cypress.io/guides/overview/why-cypress"
                target={target}
                rel={rel}
              >
                <Service>
                  <CypressImg src={CypressLogo} alt="Cypress Logo" />
                  <p>Cypress</p>
                </Service>
              </a>
              <Service>
                <a
                  href="https://www.mongodb.com/what-is-mongodb"
                  target={target}
                  rel={rel}
                >
                  <MongoImg src={MongoLogo} alt="MongoDB Logo" />
                </a>
                <p>MongoDB</p>
              </Service>
              <Service>
                <a
                  href="https://react-styleguidist.js.org/"
                  target={target}
                  rel={rel}
                >
                  <StyleguideImg
                    src={StyleGuidistLogo}
                    alt="StyleGuidist Logo"
                  />
                </a>
                <p>StyleGuidist</p>
              </Service>
              <a
                href="https://styled-components.com/docs/basics"
                target={target}
                rel={rel}
              >
                <Service>
                  <StyledCompImg
                    src={StyledComponentsLogo}
                    alt="styled components Logo"
                  />
                </Service>
              </a>
              <Service>
                <a
                  href="https://www.freecodecamp.org/news/introduction-to-mongoose-for-mongodb-d2a7aa593c57/"
                  target={target}
                  rel={rel}
                >
                  <MongooseImg src={MongooseLogo} alt="mongoose Logo" />
                </a>
                <p>mongoose</p>
              </Service>
              <Service>
                <a
                  href="https://developer.mozilla.org/en-US/docs/Learn/Server-side/Express_Nodejs"
                  target={target}
                  rel={rel}
                >
                  <ExpressImg src={ExpressLogo} alt="express Logo" />
                </a>
                <p>express</p>
              </Service>
            </ServiceContainer>
          </GridWrapper>
        </Card>
      </Wrapper>
    </StyledServices>
  );
}

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledServices = styled.section``;
const SectionTitle = styled.h2`
  margin-bottom: 0.25em;
  color: white;
`;
const ServiceContainer = styled.div`
  /* padding: 0.25em 1em; */
  display: grid;
  align-self: center;
  gap: 5rem;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;
const Service = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  margin-right: 1rem;
  color: white;
  p {
    margin-top: 0.3rem;
    text-align: center;
    font-size: 1.1em;
  }
`;



const FlutterImg = styled.img`
transition: transform 450ms;
cursor: pointer;
transform: scale(1.0);

&:hover {
  transform: scale(1.1);
}


`;

const DartImg = styled.img`
transition: transform 450ms;
cursor: pointer;
transform: scale(0.9);

&:hover {
  transform: scale(1.0);
}


`;

const JsImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const ReactImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const CssImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const HtmlImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const NodeImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const NpmImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const GithubImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const GitImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const CypressImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const MongoImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyleguideImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledCompImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  cursor: pointer;
  margin-top: 1rem;
  &:hover {
    transform: scale(1.1);
  }
`;

const MongooseImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const ExpressImg = styled.img`
  transition: transform 450ms;
  cursor: pointer;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const GridWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 20px;
  margin-top: 60px;
`;

const Card = styled.div`
  background: var(--clr-bg-opac);
  border-radius: 5px;
`;
