import { useEffect } from 'react';

export default function useScreenLock(state) {
  useEffect(() => {
    if (state) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = originalStyle);
    }
  }, [state]);
}
