import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`




*,
*::before,
*::after{
  box-sizing: border-box;

}



:root {
  --ff-primary: "Open Sans";
  --fw-reg: 300;
  --fw-bold: 900;
  
  --clr-bg: #002957;
  --clr-bg-opac: rgba(0, 41, 87, .5);
  --clr-bg-modal: rgba(0, 0, 0, 0.7);
  --clr-hov: hsla(360, 100%, 100%, 0.15);
  --clr-opac: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0.4) 100%);

  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;


  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 2.5),
  0.125em 0.125em 0.25em rgba(0, 0, 0, .15); 
  --bs2: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media(min-width: 800px){
  --fs-h1: 4.5rem;
  --fs-h2: 3.75rem;
  --fs-h3: 1.5rem;
  --fs-body: 1.125rem;
} 

html, body, #root {
  min-height: 100vH;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  margin: 0;
  line-height: 1.6;

  /* @media(min-width: 720px) {
    background-position: 50% 25%;
  }

  @media(max-width: 420px) {
    background-position: 30% 56%;
  }

  @media(max-width: 419px) {
    background-position: 30% 53%;
  }

  @media(max-width: 411px) {
    background-position: 30% 56%;
  }

  @media(max-width: 400px) {
    background-position: 30% 53%;
  }

  @media (min-height: 800px) and (max-height: 825px) {
    background-position: 30% 57%;
  }

  @media(max-width: 374px) {
    background-position: 30% 55%;
  }

  @media(max-width: 360px) {
    background-position: 30% 50%;
  }
*/
} 

img {
  display: block;
  max-width: 100%;
}

strong {
  font-weight: var(--fw-bold);
}

section {
  padding: 5em 1em;
}

h1,h2,h3 {
  line-height: 1;
  margin: 0;
}

h1 {
  font-size: var(--fs-h1);
}

h2 {
  font-size: var(--fs-h2);
}

h3 {
  font-size: var(--fs-h3);
}

`;
