import styled from 'styled-components';
import { GlobeDesktop } from '@styled-icons/fluentui-system-regular/GlobeDesktop';
import { Github } from '@styled-icons/fa-brands/Github';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { InformationCircle } from '@styled-icons/ionicons-sharp/InformationCircle';
import { ArrowDropDownCircle } from '@styled-icons/material-outlined/ArrowDropDownCircle';
import { MoveHorizontal } from '@styled-icons/boxicons-regular/MoveHorizontal';
import { KeyboardBackspace } from '@styled-icons/material-rounded/KeyboardBackspace';
import { useHistory } from 'react-router-dom';

function ProjectCard({
  technologies,
  pictures,
  description,
  deploymentLink,
  gitLink,
}) {
  const [desktopInfo, setDesktopInfo] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isVisit, setIsVisit] = useState(false);
  const [isTechnology, setIsTechnology] = useState(false);

  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';

  let history = useHistory();

  return (
    <CardWrapper>
      <Card isMobile={isMobile}>
        <InfoWrapper>
          <ReturnIcon onClick={() => history.push('/projects')} />
          <InfoContainer desktopInfo={desktopInfo}>
            <p>
              Use "shift" + "mousewheel" in order to scroll through the
              thumbnails horizontally.
            </p>
            <span onClick={() => setDesktopInfo(!desktopInfo)}>
              <ScrollIcon />
              <InfoIcon />
            </span>
          </InfoContainer>
        </InfoWrapper>
        <Cards>
          {pictures.map((picture, index) => (
            <StyledImage src={picture} alt="" key={index} isMobile={isMobile} />
          ))}
        </Cards>
        <DescriptionWrapper>
          <p>Description</p>
          <DropdownDescription
            onClick={() => setIsDescription(!isDescription)}
            isDescription={isDescription}
          />
        </DescriptionWrapper>
        <DescriptionText isDescription={isDescription}>
          {description}
        </DescriptionText>
        <Visit>
          <DescriptionWrapper>
            <p>Technologies</p>
            <DropdownTechnology
              onClick={() => setIsTechnology(!isTechnology)}
              isTechnology={isTechnology}
            />
          </DescriptionWrapper>
          <TagWrapper isTechnology={isTechnology}>
            {technologies.map((tag, index) => (
              <StyledSpan data-testid="tag" key={tag + index}>
                {tag}
              </StyledSpan>
            ))}
          </TagWrapper>
        </Visit>
        <Visit>
          <DescriptionWrapper>
            <p>Check it out</p>
            <DropdownVisit
              onClick={() => setIsVisit(!isVisit)}
              isVisit={isVisit}
            />
          </DescriptionWrapper>
          <LinkLogos isVisit={isVisit}>
          { deploymentLink != '' ?  <a href={deploymentLink} target={target} rel={rel}>
              <PageLink />
            </a> : <div></div>}
            <a href={gitLink} target={target} rel={rel}>
              <GitLogo />
            </a>
          </LinkLogos>
        </Visit>
      </Card>
    </CardWrapper>
  );
}

export default ProjectCard;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ScrollIcon = styled(MoveHorizontal)`
  color: var(--secondary-100);
  width: 40px;
`;

const ReturnIcon = styled(KeyboardBackspace)`
  color: var(--secondary-100);
  width: 40px;
  margin-left: 2rem;
  cursor: pointer;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const TagWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  border: none;
  border-radius: 5px;
  color: var(--fontcolor-primary);
  margin: 1rem;
  padding: 0;
  display: ${({ isTechnology }) => (isTechnology ? '' : 'none')};
`;

const StyledSpan = styled.span`
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--bs);
  color: black;
  font-size: 0.8rem;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 5px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;

  p {
    line-height: 1.75;
    text-align: left;
    padding-left: 1em;
  }
`;

const DescriptionText = styled.p`
  color: white;
  line-height: 1.75;
  text-align: left;
  padding: 0 1em;
  display: ${({ isDescription }) => (isDescription ? '' : 'none')};
`;

const DropdownDescription = styled(ArrowDropDownCircle)`
  color: white;
  width: 25px;
  height: 25px;
  transition: transform 550ms;
  cursor: pointer;
  transform: ${({ isDescription }) => (isDescription ? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

const DropdownVisit = styled(ArrowDropDownCircle)`
  color: white;
  width: 25px;
  height: 25px;
  transition: transform 550ms;
  cursor: pointer;
  transform: ${({ isVisit }) => (isVisit ? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

const DropdownTechnology = styled(ArrowDropDownCircle)`
  color: white;
  width: 25px;
  height: 25px;
  transition: transform 550ms;
  cursor: pointer;
  transform: ${({ isTechnology }) => (isTechnology? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1020px;
  /* width: 100%; */
  margin-right: 2rem;
  span {
    display: flex;
    flex-wrap: no-wrap;
    cursor: pointer;
    padding: 0.25em;
    transition: transform 550ms;
    &:hover {
      transform: scale(1.15);
    }
  }
  p {
    visibility: ${({ desktopInfo }) => (desktopInfo ? '' : 'hidden')};
    padding: 0rem 2rem;
    text-align: right;
  }
`;

const InfoIcon = styled(InformationCircle)`
  color: white;
  width: 40px;
  height: 40px;
`;

const PageLink = styled(GlobeDesktop)`
  color: white;
  width: 50px;
  height: 50px;
  margin-right: 3rem;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const GitLogo = styled(Github)`
  color: white;
  width: 50px;
  height: 50px;
  transition: transform 550ms;
  &:hover {
    transform: scale(1.15);
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Visit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 2rem;

  p {
  }
`;

const LinkLogos = styled.div`
  display: ${({ isVisit }) => (isVisit ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  padding: 1em 1em;
`;

const Card = styled.div`
  background: var(--clr-bg-opac);
  border-radius: 5px;
  padding: 25px 0;
  margin: 50px 0px;
  width: 100%;
`;

const Cards = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 25px 40px;
  margin-bottom: 1rem;

  /* margin-left: 4rem; */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  img:last-child {
    padding-right: 35px;
  }
`;

const StyledImage = styled.img`
  height: 500px;
  margin-right: 35px;
  transition: transform 550ms;
  &:hover {
    transform: ${({ isMobile }) => (isMobile ? 'scale(1.06)' : 'scale(1.09)')};
  }
`;
