import Burger from './Burger';
import Menu from './Menu';

export default function Sidebar({ open, setOpen }) {
  return (
    <>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </>
  );
}
