import styled from 'styled-components';
import DocumentCard from '../Components/DocumentCard';
import { documents } from '../data/Data';

export default function Vita() {
  return (
    <StyledSection>
      <Wrapper>
        <SectionTitle>Vita</SectionTitle>
        <Card>
          {documents.map((document) => (
            <DocumentCard
              file={document.file}
              title={document.title}
              subtitle={document.subtitle}
              key={document.id}
            />
          ))}
        </Card>
      </Wrapper>
    </StyledSection>
  );
}

const StyledSection = styled.section``;
const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  margin-bottom: 0.25em;
  color: white;
`;

const Card = styled.div`
  background: var(--clr-bg-opac);
  border-radius: 5px;
  padding: 25px 0;
  margin: 50px 0px;
  width: 100%;
`;
